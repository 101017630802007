.verify-page {
	display: flex;
	flex-direction: column;
	justify-content: center;

	flex-grow: 0;

	height: 100%;

	.verify-page-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	ion-img {
		max-width: 380px;
	}

	h1 {
		margin-top: 40px;

		max-width: 100vw;
	}

	ion-button {
		margin-top: 15px;
		margin-bottom: 40px;
	}
}

.verify-failed-toast {
	text-align: center;
}
