.center {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: auto;

	p {
		margin: auto;
	}

	@media only screen and (min-width: 400px) {
		form {
			width: 400px;
			margin: 0 auto;
		}
	}

	ion-checkbox {
		width: auto;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

.reCaptchaStyle {
	margin: unset !important;
	left: 50vw !important;
	transform: translateX(-50%);

	@media only screen and (max-width: 400px) {
		transform: translateX(-50%) scale(0.8);
	}

	@media only screen and (max-width: 325px) {
		transform: translateX(-50%) scale(0.65);
	}
}

small {
	color: #666;
}

@media (prefers-color-scheme: dark) {
	small {
		color: #ccc;
	}
}

ion-chip {
	display: block;
	height: auto;
	text-align: center;
}

ion-chip:empty {
	display: none;
}

ion-modal.fullscreen {
	--width: 100%;
	--height: 100%;
	--border-radius: 0;
}

.popupAlert {
	$top-distance: 75px;

	position: fixed;
	left: 50%;
	top: $top-distance;
	transform: translateX(-50%) scale(1.2);
	z-index: 100;
	transition: all 0.3s ease-in-out;
	animation: popupAlertForwards 0.3s ease-in-out both;
	width: max-content;
	max-width: 50%;

	@media only screen and (max-width: 900px) {
		max-width: 70%;
	}

	@keyframes popupAlertForwards {
		0% {
			top: -20px;
			transform: translateX(-50%) scale(0.8);
			opacity: 0;
		}
		100% {
			top: $top-distance;
			transform: translateX(-50%) scale(1.2);
			opacity: 1;
		}
	}

	@keyframes popupAlertBackwards {
		0% {
			top: $top-distance;
			transform: translateX(-50%) scale(1.2);
			opacity: 1;
		}
		100% {
			top: -20px;
			transform: translateX(-50%) scale(0.8);
			opacity: 0;
		}
	}
}
