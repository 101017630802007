.plaatjes {
	background-image: url("../../../../resources/page_backgrounds/plakkaart.png");
	background-position: 80%;

	.content {
		display: grid;
		grid-template-columns: repeat(var(--plaatjes_columns), 1fr);
		grid-template-rows: repeat(var(--plaatjes_rows), 1fr);

		.sticker_date {
			color: black; 
			border: none;
			text-align: center;
			opacity: 0;
		}
		/*.sticker_date::-webkit-calendar-picker-indicator {
			opacity: 0.7;
		}*/
	}
}
