.over_mij {
	background-image: url("../../../../resources/page_backgrounds/verhaal.png");
	background-position: 80%;

	.about_me_content {
		width: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;

		div {
			width: 70%;

			div {
				width: 100%;
			}
		}
	}

	.block {
		display: block;
	}

	.length-input {
		flex-direction: row-reverse;
	}
}
