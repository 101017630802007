.verhaal {
	background-image: url("../../../../resources/page_backgrounds/verhaal.png");
	background-position: 80%;

	.header {
	}

	.content {
	}
}
