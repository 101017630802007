.horizontalScroll {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	margin-bottom: 1fr;
	z-index: 1;
	position: relative;

	ion-card:not(.diary-addButton) {
		min-width: 200px;
		width: 220px;
	}
}
.coverImage {
	padding: 0;
	margin: 0;
	border: 0;
	width: 100%;
	align-self: center;
}

.centerItems{
	//display: flex;
	align-items: center;
	justify-content: center;
}
.grid {
	position: relative;
	left: 0;
	right: 0;
}
.diary-edit {
	background-color: white;
	/*border: 1px solid white;
	--background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
	--color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-550, #737373)));*/
	border-radius: 4px;
	font-size: 14px;
	box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
	/*margin-bottom: 20px;
	border-right: 10px solid #ffffff;
	border-left: 10px solid #ffffff;*/
	z-index: 1;
	position: relative;
	cursor: pointer;
	transition: .5s;

	div {
		margin: 25px;
		p {
			display: inline-block;
		}
	}
}

.diary-edit:hover {
	background-color: rgb(247, 247, 247);
}

.diary-addButton {
	ion-button {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		transform: translate(-2%, -6%);

		ion-icon {
			font-size: 30px;
		}
	}
}
.grid{
	background-color: transparent;
}
