.chapter_view_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 10px 10% 10px 10%;

	h1 {
		margin: 0;
	}
}

.side-border {
	padding-top: 56px;
	position: fixed;
	right: 0;
	display: flex;
	align-content: center;
	justify-content: center;
	top: 0;
	bottom: 0;

	& div {
		height: 60vh;
		width: 25px;
		right: 0;
		background: white;
		box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
		border-radius: 1000px;
		transform: translateX(50%);
		margin: auto;
	}
}

.menu-content {
	transform: none;
}

.page-view {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	overflow-x: auto;

	margin-top: 10px;

	ion-card {
		//min-width: 98%;
		//margin-inline: 1%;
		background-color: var(--dapper-primary);
		color: white;
	}
}

#main-child::part(scroll) {
	// Hide the scrollbar
	// Hide scrollbar for Chrome, Safari and Opera
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

ion-modal.add-page-modal {
	--width: fit-content;
	--min-width: 250px;
	--height: max-content;
	--border-radius: 6px;
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

	.create-page-radio-label {
		padding-bottom: 20px;
	}

	ion-radio-group {
		width: 100%;
	}
}

.page-index-menu {
	position: sticky;
	height: 100%;
	width: 100%;
}

.add_fab {
	bottom: 30px;
	right: 18px;
}
