.aspect-ratio-selector {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	flex-wrap: wrap;

	ion-radio {
		margin: 11px;
	}

	ion-label {
		margin: 5px;
	}

	.aspect-ratio-selector-title {
		width: 100%;
		margin: 0;
		text-align: center;
		font-weight: bold;
	}
}

.App.crop-zoom-controls {
	top: 55px;
}
