.login-form {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	* {
		width: 100%;
		// min-height: 35px;
	}

	.forgotten_password {
		background-color: transparent;
	}

	ion-img {
		margin-bottom: 15px;
	}

	@media only screen and (min-width: 400px) {
		ion-img {
			width: 100%;
			margin: 0px auto;
			margin-bottom: 15px;
		}
	}

	ion-checkbox {
		width: auto;
	}

	.resend-verify {
		p {
			padding: 0;
			margin: 0;
		}
		p:nth-child(2) {
			margin-top: 5px;
		}

		display: none;
	}
}

.login-form-divider {
	--padding-start: 0;
}
