.login-form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 690px;

	@media only screen and (min-width: 400px) {
		form {
			width: 380px;
			margin: 0 auto;
		}
	}
}
