.sticker-overview-single-card {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 10px;

	aspect-ratio: 1 / 1;

	cursor: pointer;

	.sticker-overview-single-image {
		object-fit: cover;
		width: 100%;
	}
}
