.barBackButton {
	display: flex;
	text-decoration: none;
	font-size: 18px;
	line-height: 32px;
	align-items: center;
	color: var(--color);

	ion-icon {
		font-size: 30px;
		margin-right: 3px;
	}
}

ion-modal#birthday {
	--width: 400px;
	--height: 400px;
	img {
		height: 300px;
		margin-bottom: 10px;
	}
	div:nth-child(1) {
		text-align: center;
		span:nth-child(1) {
			color: var(--dapper-ouder-primary);
		}
		span:nth-child(2) {
			color: var(--dapper-kind-tertiary);
		}
		span:nth-child(3) {
			color: var(--dapper-brus-tertiary);
		}
		span:nth-child(4) {
			color: var(--dapper-brus-secondary);
		}
		span:nth-child(5) {
			color: var(--dapper-kind-primary);
		}
		span:nth-child(6) {
			color: var(--dapper-ouder-secondary);
		}
		span:nth-child(7) {
			color: var(--dapper-ouder-primary);
		}
		span:nth-child(8) {
			color: var(--dapper-kind-tertiary);
		}
		span:nth-child(9) {
			color: var(--dapper-brus-tertiary);
		}
		span:nth-child(10) {
			color: var(--dapper-brus-secondary);
		}
		span:nth-child(11) {
			color: var(--dapper-kind-primary);
		}
		span:nth-child(12) {
			color: var(--dapper-ouder-secondary);
		}
	}
}
.space {
	margin-right: 10px;
}
.gefeliciteerd {
	span {
		font-size: 40px;
	}
}
.met_je_verjaardag {
	font-size: 25px;
	.met {
		color: var(--dapper-ouder-secondary) !important;
	}
	.verjaardag {
		color: var(--dapper-brus-secondary) !important;
	}
}
