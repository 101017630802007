//.personal_info_general {
//	margin-bottom: 20px;
//}
//@media (min-width: 768px) {
//	.first_field_move_margin {
//		margin-left: 70px;
//	}
//	.second_field_move_margin {
//		margin-left: 70px;
//	}
//}
.button_email {
	position: relative;
	float: right;
}
.date {
	margin-top: -25px;
	--border-color: white;
}
b {
	position: relative;
	top: 3px;
	left: 10px;
}
ion-badge {
	--padding-bottom: 5px;
	--padding-left: 5px;
	--padding-right: 5px;
	--padding-top: 5px;
}
ion-item {
	border-bottom: 1px;
}
.personal_buttton {
	margin-top: 12px;
}
.birthdate {
	margin-top: 15px;
}
ion-modal.auto-height {
	&.bottom {
		align-items: flex-end;
	}

	--height: auto;

	.ion-page {
		position: relative;
		display: block;
		contain: content;

		.inner-content {
			max-height: 80vh;
			overflow: auto;
			padding: 10px;
		}
	}
}
