.tekenkaart {
	background-image: url("../../../../resources/page_backgrounds/tekenkaart.png");
	background-position: 80%;

	.content {
		min-height: 100%;
		width: 100%;
		padding: 7px;
	}

	#colorPalette {
		background-color: white;
		border: 1px solid black;
		width: 610px;
		border-radius: 5px;
		margin-bottom: 10px;
		position: absolute;
	}

	.paletteCol {
		width: 50px;
		height: 50px;
		border: 1px solid black;
		display: inline-block;
		margin: 5px;
		border-radius: 2px;
	}

	#brushSize {
		background-color: white;
		border: 1px solid black;
		width: 250px;
		border-radius: 5px;
		text-align: center;
		position: absolute;
	}

	.slider {
		width: 80%;
	}
}
