.diary-header {
	background: linear-gradient(to bottom, var(--dapper-primary), var(--dapper-secondary), var(--dapper-tertiary));

	& > ion-card-header > ion-card-title {
		color: white;
	}
}

.books {
	position: relative;
	top: 0;
	transform: translateY(-50%, -50%);

	.back_photo {
		height: 80vh;
	}
}
.image_cover_main {
	position: relative;
}
.cover_photo {
	position: absolute;
	top: 200px;
	width: 250px;
	height: 250px;
	left: 530px;
}

.diary-delete-button {
}

.diary-title {
	display: flex;
	justify-content: space-between;

	ion-input {
		width: 100%;
	}

	ion-icon {
		font-size: 1rem;
		margin: 0.5em 0;
	}

	ion-button {
		height: auto !important;

		&.rounded-circle {
			height: 4em !important;
			padding: 0.5em;

			ion-icon {
				font-size: 1.5rem;
			}
		}
	}
}
