.sticker_slot_sticker.sticker_field {
    width: 100px;
}

.sticker_slot_text {
    width: 100%;

    display: block;
    padding: 7px 7px;

    //textarea styling
    border: none;
    border-radius: 5px;
    resize: none;
    line-height: 1;
}

.sticker_slot_text:focus {
    outline: 1px solid rgb(0, 0, 0);
}

.sticker_slot_button {
    width: 100%;
    height: 100%;
    color: gray;
}

// custom scrollbar
::-webkit-scrollbar {
	width: 10px;
}
	
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px rgb(177, 177, 177); 
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #a5a5a5; 
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #969696; 
}