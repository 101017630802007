.welcome-text {
	text-align: center;
	padding: 10px 40px;
	background: white;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.center {
	flex-direction: column;
	margin-top: 150px !important;
}

.background {
	position: absolute;
	background-image: url(../resources/page_backgrounds/achtergrond-dagboek.png);
	background-size: cover;
	background-repeat: repeat;
	z-index: -10;
	width: 100%;
	height: 100%;
}

.indexGrid {
	display: grid;
	width: 80%;
	grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
	grid-template-rows: repeat(auto-fit, minmax(10rem, 1fr));
	grid-gap: 10px;
	margin-bottom: 5rem;

	ion-card {
		max-width: 10rem;
	}

	@media only screen and (max-width: 420px) {
		grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
		grid-template-rows: repeat(auto-fit, minmax(8rem, 1fr));
		width: 100%;
	}
}

$animation-length: 5s;

.zon-wrapper {
	width: 300px;
	position: absolute;
	top: 275px;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
	animation: drawArc1 $animation-length ease-in-out;
	animation-fill-mode: both;
	overflow: hidden;
}

.zon {
	display: inline-block;
	width: 150px;
	height: 150px;
	animation: drawArc2 $animation-length ease-in-out;
	animation-fill-mode: both;
}

$translate: translate(-50%, -50%);

@keyframes drawArc1 {
	0% {
		transform: $translate rotate(0deg);
		width: 300px;
	}
	100% {
		transform: $translate rotate(90deg);
		width: 540px;
	}
}

@keyframes drawArc2 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-90deg);
	}
}
