.page_content {
	max-width: 100%;
	// max-height: 78vh;
	min-height: max-content;
}

.page_view_card {
	position: relative;
	margin: 0 20% 20px 20%;

	min-width: 480px;

	.page_view_card_content {
		padding: 0;
	}
}
