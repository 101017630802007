.confirm-form {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	* {
		width: 100%;
	}

	ion-icon {
		width: 30px;
	}

	ion-text {
		text-align: center;
	}
}
