.page_type_page {
	display: grid;
	grid-template-rows: max-content 1fr;

	min-height: 55vmax;
	// min-height: 80vh;

	padding: 8px 16px;

	background-size: cover;
	background-repeat: no-repeat;

	.trans_white_bg {
		background-color: rgba(255, 255, 255, 0.692);
		border-radius: 5px;
		color: black;
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;

		p {
			padding: 0;
			// margin-top: 8px;
			color: white;
		}
	}

	.content {
		.sticker_field {
			width: 100px;
			aspect-ratio: 1/1;
			background-color: rgb(248, 248, 248);
			border: 1px solid rgb(111, 106, 106);
			box-shadow: 0px 0px 10px 1px rgb(111, 106, 106);
			display: inline-block;
			margin: 5px;
			border-radius: 5px;
		}
	}
}
