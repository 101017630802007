ion-grid {
	border-radius: 5px;
	margin: 10px;
	color: black;
	background-color: white;
	ion-row {
		div {
			position: relative;
			z-index: 99;
		}

		ion-col {
		}
		.buttons {
			position: absolute;
			right: 0;
			margin-right: 40px;
		}
	}
}
