.voorgeschiedenis {
	background-image: url("../../../../resources/page_backgrounds/voorgeschiedenis.png");

	.text0 {
		grid-area: text1;
	}
	.text3 {
		grid-area: text2;
	}
	.text4 {
		grid-area: text3;
	}
	
	
	.sticker1 {
		grid-area: sticker1;
	}
	.sticker2 {
		grid-area: sticker2;
	}
	.sticker5 {
		grid-area: sticker3;
	}

	.content {
		max-height: 65%;
		top: 35%;
		width: 100%;
		padding: 10px;

		position: relative;

		display: grid;
		grid-template-areas: 
			"text1 text1 text1 text1 text1 sticker1"
			"sticker2 text2 text2 text2 text2 text2"
			"text3 text3 text3 text3 text3 sticker3";
		gap: 15px;

		.sticker_date {
			color: black; 
			border: none;
			text-align: center;
			opacity: 0;
		}
	}
}
