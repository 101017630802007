.terms_container {
	padding: 20px;

	.terms_header {
		h1 {
			font-size: 1.5em;
			font-weight: bolder;
			margin: 5px;
			margin-top: 15px;
		}
		h4 {
			font-size: 1.25em;
			margin: 5px;
			font-weight: bold;
		}
		h6 {
			font-size: 1em;
			margin: 5px;
		}
	}

	.terms_sub_header {
		margin-top: 25px;
	}

	.terms_article {
		margin-top: 20px;

		.terms_article_title {
			font-weight: bold;
			display: block;
			text-align: left;
		}

		.terms_article_list {
			.terms_article_list_item {
				padding-bottom: 2px;
				padding-left: 10px;
				text-align: left;
			}
		}
	}

	.terms_footnote {
		p {
			font-size: 0.75em;
			margin-top: 10px;
			padding-bottom: 25px;
		}
	}
}
p {
	text-align: left;
}
