.page-media-image {
	object-fit: fill;
}

ion-range {
	position: absolute;
}

.h-slider {
	width: 150px;
	margin: 0 auto;
	top: -75px;
	padding: 0px;
	display: none;
}

.visible {
	display: flex !important;
}

.page-media-image {
	width: 100%;
	height: 100%;
}

.resizer_button {
	rotate: 90deg;
}

.photo_controls {
	display: flex;
	flex-direction: row;
}
