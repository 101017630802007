/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #5f97d1;
	--ion-color-primary-rgb: 90, 148, 213;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #2d2e83;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #9dc01c;
	--ion-color-secondary-rgb: 147, 193, 34;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #006633;
	--ion-color-secondary-tint: #aad835;

	/** tertiary **/
	--ion-color-tertiary: #e71a83;
	--ion-color-tertiary-rgb: 231, 26, 131;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #662483;
	--ion-color-tertiary-tint: #f73b9c;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** Dapperkind **/
	--dapper-kind-primary: #0083f5;
	--dapper-kind-secondary: #00CCFF;
	--dapper-kind-tertiary: #1a8821;

	/** Dapper broer/zus **/
	--dapper-brus-primary: #0083f5;
	--dapper-brus-secondary: #00CCFF;
	--dapper-brus-tertiary: #1a8821;

	/** Dapper ouder/verzorger **/
	--dapper-ouder-primary: #0083f5;
	--dapper-ouder-secondary: #00CCFF;
	--dapper-ouder-tertiary: #1a8821;

	/** Current role colors **/
	/** These colors will default to dapper-kind, but can be changed programmatically later **/
	--dapper-primary: var(--dapper-kind-primary);
	--dapper-secondary: var(--dapper-kind-secondary);
	--dapper-tertiary: var(--dapper-kind-tertiary);
}
