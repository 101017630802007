.register-form {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	* {
		width: 100%;
	}

	ion-item {
		&.wrong {
			--border-width: 1;
			--border-color: var(--ion-color-danger-shade);
			--border-radius: 10px;
			animation: shake 1s;
		}
	}
}

.register_role_select {
	max-width: max-content;
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
