.loading-modal-container {
	position: absolute;
	left: 0;
	top: 0;

	height: 100%;
	width: 100%;

	background-color: #928e8e65;

	display: flex;
	align-items: center;
	justify-content: center;

	.lottie-container {
		svg {
			width: 40vw !important;
			height: 40vw !important;

			min-width: 450px;
			min-height: 450px;
		}
	}
}
