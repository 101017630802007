.admin-rights {
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;

	.section {
		width: 100%;
	}

	ion-item {
		width: 80%;
	}

	.filter-select {
		background-color: LightBlue;
		padding: 1em;

		div {
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		.choose-filter {
			//background-color: rgb(255, 142, 185);
			margin-bottom: 1em;
			flex-direction: column;
			padding: 1em 0;

			div {
				width: 100%;
			}
		}

		ion-item {
			min-height: 55px !important;
			width: 100%;
			display: block;
			margin: 0 4px;
		}
	}

	.pages {
		color: blue;
		cursor: pointer;
	}

	.pages-horizontal {
		margin: 0 5px;
	}

	ion-list {
		* {
			box-sizing: border-box;
		}
		ion-item {
			width: 100%;
			.users {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				flex-wrap: wrap;

				div {
					justify-content: center;
					align-items: center;
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	.admin-rights {
		ion-list {
			ion-item {
				.users {
					flex-direction: row;
					.userPart {
						flex: 70%;
					}

					.buttons {
						flex: 30%;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	.admin-rights {
		.section {
			width: 80%;
		}

		.filter-select {
			padding: 2em;

			ion-item {
				width: 45%;
				display: inline-block;
			}

			div {
				flex-direction: row;
			}
		}

		ion-item {
			width: 40%;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.admin-rights {
		.section {
			width: 60%;
		}

		ion-item {
			width: 35%;
		}

		.filter-select {
			ion-item {
				width: 40%;
				display: inline-block;
			}
		}
	}
}
