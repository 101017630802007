.register-form-container {
	min-height: 1020px;
}

.center.center {
	@media only screen and (min-width: 440px) {
		form {
			width: 440px;
			margin: 0 auto;
		}
	}
}
