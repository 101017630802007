.confirm_delete_parent {
	display: flex;
	justify-content: center;
	align-items: center;

	.failed,
	.succes {
		width: 380px;
		margin: 0 auto;

		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		position: relative;
		bottom: 50px;

		p {
			padding: 0;
		}

		ion-button {
			margin-top: 30px;
		}

		ion-img {
			width: 250px;
		}

		@media only screen and (max-width: 300px) {
			ion-img {
				width: 80%;
			}
		}
	}
}
