.sticker-overview-container {
	@media screen and (max-width: 480px) {
		--columns: 3;
	}
	@media screen and (min-width: 481px) and (max-width: 768px) {
		--columns: 4;
	}
	@media screen and (min-width: 769px) and (max-width: 1024px) {
		--columns: 6;
	}
	@media screen and (min-width: 1025px) and (max-width: 1200px) {
		--columns: 6;
	}
	@media screen and (min-width: 1201px) {
		--columns: 7;
	}

	.grid {
		display: grid;
		grid-template-columns: repeat(var(--columns), 1fr);
		grid-gap: 15px;

		margin: 15px;
	}
}

.sticker-modal {
	ion-content {
		display: flex;
		justify-content: center;
	}

	img {
		width: 100%;
		max-height: 60%;
		object-fit: contain;
		margin: 0 auto;
		aspect-ratio: 1 / 1;
	}

	ion-content {
		ion-title {
			padding: 0;
			margin-top: 10px;
			text-align: center;
		}
	}

	p {
		padding: 0;
		text-align: center;
	}
}
