.placeable-item {
	cursor: move;
	user-select: none;
	position: absolute;

	width: max-content;
	max-width: 30rem;

	white-space: pre-line;

	ion-textarea {
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-left: 0 !important;
		--padding-right: 0 !important;
		--padding-start: 0 !important;
		--padding-end: 0 !important;
		margin-top: 0 !important;
	}

	.delete-button {
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		margin-top: 0 !important;
		position: absolute;
		top: -40px;
		right: 0;
	}

	.move-button {
		cursor: move;
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		margin-top: 0 !important;
		position: absolute;
		top: -40px;
		right: 45px;
	}

	.resize-button {
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		margin-top: 0 !important;
		position: absolute;
		top: -40px;
		right: 90px;
	}

	.hidden-button {
		display: none;
	}
}

.placeable-item-big {
	cursor: move;
	user-select: none;

	white-space: pre-line;

	ion-textarea {
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-left: 0 !important;
		--padding-right: 0 !important;
		--padding-start: 0 !important;
		--padding-end: 0 !important;
		margin-top: 0 !important;
	}

	.delete-button {
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		margin-top: 0 !important;
		position: absolute;
		top: -40px;
		right: 0;
	}

	.move-button {
		cursor: move;
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		margin-top: 0 !important;
		position: absolute;
		top: -40px;
		right: 45px;
	}

	.resize-button {
		--padding-top: 0 !important;
		--padding-bottom: 0 !important;
		--padding-start: 10px !important;
		--padding-end: 10px !important;
		margin-top: 0 !important;
		position: absolute;
		top: -40px;
		right: 90px;
	}

	.hidden-button {
		display: none;
	}
}

.placeable-editing {
	border: 2px solid var(--dapper-secondary);

	.hidden-button {
		display: inline-block;
	}
}
